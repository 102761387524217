import docReady from '../../../assets/js/docReady';
import { clickableTable, scrollableTable } from '../../../assets/js/helpers/table';

docReady(() => {
    document.querySelectorAll('.flamingo-table-sim-only.table-container')
        .forEach((table) => {
            clickableTable(table);
            scrollableTable(table);
        });
});
